<template>
  <div>
    <div class="overlay"></div>
    <div>
      <p class="mx-auto mb-6 text-4xl">Miljøkalkulator</p>
      <div v-if="result" class="grid gap-2">
        <dl class="grid grid-cols-2 gap-2">
          <dt>CO<sub>2</sub> redusert:</dt>
          <dd>{{ result.resultCo2 }}</dd>
          <dt>Avfall spart:</dt>
          <dd>{{ result.resultWaste }}</dd>
          <dt>Skog/antall trær reddet:</dt>
          <dd>{{ result.resultForest }}</dd>
          <div class="mt-2">
            <PButton @click="resetForm">Ny kalkulering</PButton>
          </div>
        </dl>
      </div>
      <form v-else :action="calculate" class="flex flex-col gap-2">
        <template v-if="step === 1">
          <PSelect v-model="selectedMaterial" :items="materialOptions" label="Palletype" />
          <PNumber v-model="quantity" label="Antall" :min="0" :step="1" />
        </template>
        <template v-else-if="step === 2">
          <PInput
            v-model="email"
            :clearable="true"
            :required="true"
            label="E-post"
            placeholder="E-post"
            type="email"
          />
          <PInput
            v-model="name"
            :clearable="true"
            :required="true"
            label="Navn"
            placeholder="Navn"
          />
          <PInput
            v-model="company"
            :clearable="true"
            :required="true"
            label="Selskap"
            placeholder="Selskap"
          />
        </template>
        <PButton class="mt-4" @click="calculate">{{ step === 1 ? 'Kalkuler' : 'Se resultat' }}</PButton>
        <PError v-if="errorMsg">{{ errorMsg }}</PError>
      </form>
    </div>
  </div>
</template>

<script>
import http from '@/utilities/http';
import LocaleService from '@/services/LocaleService';
import PError from '@/components/PError.vue';

export default {
  components: { PError },
  data() {
    return {
      email: '',
      name: '',
      company: '',
      selectedMaterial: null,
      quantity: 0,
      errorMsg: '',
      result: null,
      step: 1
    };
  },
  computed: {
    isPlastic() {
      return this.selectedMaterial === 'plastic';
    },
    materialOptions() {
      return [
        { text: 'Plastpaller', value: 'plastic' },
        { text: 'Trepaller', value: 'wood' }
      ];
    }
  },

  methods: {
    async calculate() {
      if (this.step === 1 && this.quantity && this.selectedMaterial) {
        this.errorMsg = '';
        this.step = 2;
        return;
      }
      if (!this.email.length || !this.name.length || !this.company.length || !this.quantity || !this.selectedMaterial) {
        this.errorMsg = 'Alle felt må fylles ut';
        return;
      }
      this.errorMsg = '';
      try {
        this.result = await http.post('EnvironmentCalculatorContact', {
          email: this.email,
          name: this.name,
          company: this.company,
          contact: true,
          plastic: this.isPlastic,
          qty: this.quantity
        });
      } catch (error) {
        console.error(error);
        this.errorMsg = `Kunne ikke kalkurere utslipp. ${
          error.reason ? error.reason : 'Se over skjemaet og prøv igjen.'
        }`;
      }
    },
    resetForm() {
      this.step = 1;
      this.errorMsg = ''
      // ** step 1
      this.quantity = 0;
      this.selectedMaterial = null;
      // ** step 2
      this.email = '';
      this.name = '';
      this.company = '';
      // ** result
      this.result = null;
    }
  },

  mounted() {
    console.log('Mounted carbon footprint calculator 😈');
    this.$i18n.locale = LocaleService.getLocale();
  }
};
</script>

<style scoped></style>
