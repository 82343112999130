<template>
  <div>
    <div class="overlay"></div>
    <div class="overflow-auto">
      <p class="mx-auto mb-6 text-4xl">Time Period: {{ period }}</p>
      <table class="flex flex-col gap-2">
        <thead>
          <tr>
            <th>Volume (ton)</th>
            <th class="right">tCO₂e/ton</th>
            <th class="right">Total tCO₂e <br/>from recycling</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="row in rows"
            :key="`calculation-${row.id}`"
          >
            <td>
              <PNumber
                v-model="row.volume"
                :label="row.label"
                :placeholder="row.label"
              />
              <!-- <PNumberSimple
                v-model="row.volume"
                :label="row.label"
                :placeholder="row.label"
                :step="0.01"
              /> -->
            </td>
            <td class="right">{{ row.percent.toFixed(6) }}</td>
            <td class="right">{{ Number(row.volume) * Number(row.percent) > 0 ? (Number(row.volume) *
              Number(row.percent)).toFixed(6) : '' }} <i
                v-if="Number(row.volume) * Number(row.percent) > 0">tCO₂e</i>
            </td>
          </tr>
          <tr class="bold">
            <td
              colspan="2"
              class="font-bold"
            >Sum</td>
            <td class="font-bold right">{{ sum }} <i>tCO₂e</i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import LocaleService from '@/services/LocaleService';

export default {
  data() {
    return {
      period: `Q1 ${(new Date()).getFullYear()}`,
      rows: [
        {
          id: 'pressure-impregnated-wood',
          label: 'Trykkimpregnert tre',
          volume: 0,
          method: 'Energy recovery',
          percent: 0.001469,
        },
        {
          id: 'unprocessed-wood',
          label: 'Ubehandlet tre',
          volume: 0,
          method: 'Material recycling',
          percent: 0.001469,
        },
        {
          id: 'varnished-or-oiled-wood',
          label: 'Lakkert eller oljet tre',
          volume: 0,
          method: 'Energy recovery',
          percent: 0.001469,
        },
        {
          id: 'clinker-tiles',
          label: 'Klinker/Fliser',
          volume: 0,
          method: 'Landfill',
          percent: 0.000008,
        },
        {
          id: 'rubble',
          label: 'Murbrokker',
          volume: 0,
          method: 'Landfill',
          percent: 0.000008,
        },
        {
          id: 'roof-tiles-concrete',
          label: 'Tagsten betong',
          volume: 0,
          method: 'Energy recovery',
          percent: 0.000000235,
        },
        {
          id: 'roof-tiles',
          label: 'Taksten tegl',
          volume: 0,
          method: 'Landfill',
          percent: 0.000417538461538462,
        },
        {
          id: 'fiber-cement',
          label: 'Fibercement (bølgeplade)',
          volume: 0,
          method: 'Landfill',
          percent: 0,
        },
        {
          id: 'plasterboard',
          label: 'Gipsplade (standard)',
          volume: 0,
          method: 'Energy recovery',
          percent: 0.0000862222222222222,
        },
        {
          id: 'glass-walls',
          label: 'Glassvægge (inkl montering, tykkelse mindre enn 15 mm)',
          volume: 0,
          method: 'Material recycling',
          percent: 0.0000866387096774193,
        },
        {
          id: 'pu-joint-foam',
          label: 'PU fugeskum (?)',
          volume: 0,
          method: 'Energy recovery',
          percent: 0.00272,
        },
        {
          id: 'reinforcing-steel',
          label: 'Armeringsstål',
          volume: 0,
          method: 'Energy recovery',
          percent: 0,
        },
        {
          id: 'steel-plate',
          label: 'Stål, plade',
          volume: 0,
          method: 'Energy recovery',
          percent: 0.00000273,
        },
        {
          id: 'pex-pipes-pipe-in-pipe',
          label: 'Pex rør, brugsvand, rør i rør, 12 mm (finnes flere str og varianter)',
          volume: 0,
          method: 'Energy recovery',
          percent: 0.00336469375,
        }
      ],
    };
  },
  computed: {
    sum() {
      let sum = 0;
      for (const index in this.rows) {
        sum += (Number(this.rows[index].volume) * Number(this.rows[index].percent))
      }
      return sum.toFixed(2)
    }
  },
  mounted() {
    this.$i18n.locale = LocaleService.getLocale();
  }
};
</script>

<style scoped>
table {
  @apply block border border-gray-500 rounded;
}

tr {
  @apply border border-gray-500;
}

th,
td {
  @apply px-2 py-4 text-left;

  &.right {
    @apply text-right;
  }

  &:first-of-type {
    @apply pl-4;
  }

  &:last-of-type {
    @apply pr-4;
  }
}
</style>
